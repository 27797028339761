
const ConstantConfig = {
    exam: {
        technicalBufferTime: 15,
        hideReportId: 'aefa3719-88ce-4625-8074-78e8a4bb7479' //polytech
        // hideReportId: 'e7cc45a6-cf5b-42b0-bf18-0e41db9dcdbd' //sgiadmin
    }
}

export default ConstantConfig;
