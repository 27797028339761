
// Simple File for TTheme consideration and Design

const AppTheme = {
    light: {
        fontSize: "10px",
        textColor: "#EA7773",
        backgroundColor: "#FFF",
        border: "4px grove #000",
    },
    dark: {
        textColor: "#fff",
        backgroundColor: "#333",
        border: "4px grove #000"
    }


    // contrast: {
    //     fontSize: "10px",
    //     textColor: "#192A56",
    //     backgroundColor: "#53E0BC",
    //     border: "4px grove #2C3335",
    // }
};

export default AppTheme;
