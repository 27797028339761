import React from 'react';
import { LoginForm } from '../../components';
import SignIn from '../../components/auth/loginForm/SignInForm';
const Login = () => {

    return (<>
        {/* <LoginForm /> */}
        <SignIn />
    </>
    )

}

export default Login;