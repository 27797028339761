import React, { useState, useEffect } from 'react';
import ParticlesBg from 'particles-bg'

const TermsPrivacyPolicy = (props) => {

    return (

        <div>
            {
                props.match.params.userId === '1' ? <div className="terms-privacy-policy">
                    <ParticlesBg type="cobweb" bg={true} color="#18dcff" />
                    <h3>Terms of Use</h3>
                    <p>These Terms & Conditions (“Terms”) of (a)“www.sglearning.in” (hereinafter, the “Website” or “Site”) and the mobile learning application in relation thereto (“Application”) is owned and operated by Ghodawat Softech LLP, a company incorporated under the Companies Act, having its registered office at Plot. No. 437/8, At Po Chipari, Jaysingpur, Tal. Shirol, Dist Kolhapur-416101, Maharashtra, India. “sglearning.in” is a web console for educational organizations / Institutes  and “SG Learning App” is a mobile app (Android and iOS) will be considered as “SG Learning” throughout the document. By using the SG Learning, you accept and agree to be bound by the following terms and conditions. Nothing contained in these Terms of Service (ToS) should be construed to confer any rights to a third party beneficiary. </p>
                    <p>This document is an electronic record published in accordance with the provisions of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the Rules and Regulations, Privacy Policy and Terms of Service for access or usage of the website and being generated by a computer system, it does not require any physical or digital signatures. For the purpose of these Terms of Use, along with any amendments to the same, and wherever the context so requires “You”, “Your” or “User” refer to the person visiting, accessing, browsing through and/or using the Website/Application or using the Services or Products, at any point in time. The term “We”, “Us”,“Our” shall mean and refer to the Website and/or the Company, depending on the context. The headings on each section in this Agreement are only for the purpose of organising the various provisions under thisAgreement in an orderly manner. These headings shall not be used by either party to interpret the provisions contained under them in any manner.</p>
                    <p>Please read the Terms and the privacy policy of the Company (“Privacy Policy”) with respect to registration with us, the use of the Application, Website, Services and products carefully before using the Application, Website, Services or products. In the event of any discrepancy between the Terms and any other policies with respect to the Application or Website or Services or products, the provisions of the Terms shall prevail.</p>
                    <p>Your use/access/browsing of the Application or Website or the Services or products or registration (with or without payment/with or without subscription) through any means shall signify Your acceptance of the Terms and Your agreement to be legally bound by the same.</p>
                    <p>If You do not agree with the Terms or the Privacy Policy, please do not use the Application or Website or avail the Services or products. Any access to our Services/Application/products through registrations/subscription is non transferable.</p>
                    <label>Eligibility, Registration And Access</label>
                    <p>The decision to grant access to the services of SG Learning is the prerogative of the site and its decision in this respect shall be final and binding on the proposed user. The user consents that SG Learning reserves its right to take all necessary measures to deny access to its services or the termination of services, if the terms and conditions of the present agreement are not complied with or are contravened or there is any violation of copyright, trademark or other valuable proprietary right as described in this agreement. </p>
                    <span>Use of this Application, Website, products and / or our Services shall be subjected to the following restrictions:</span>
                    <p>1.	You may not decompile, reverse engineer, or disassemble the contents of the Application and / or our Website and / or Services / products or modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Application and / or our Website and/or Services/products, or remove any copyright, trademark registration, or other proprietary notices from the contents of the Application and / or and / or our Website and/or Services / products.</p>
                    <p>2.	No User shall be permitted to perform any of the following prohibited activities while availing our Services:</p>
                    <ul className="alpha-list">
                        <li>Making available any content that is misleading, unlawful, harmful, threatening, abusive, tortious, defamatory, libelous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable;</li>
                        <li>Stalking, intimidating and/or harassing another and/or inciting another to commit violence;</li>
                        <li>Transmitting material that encourages anyone to commit a criminal offence, that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice;</li>
                        <li>Interfering with any other person’s use or enjoyment of the Application/Website/Services;</li>
                        <li>Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner, committing any act that amounts to the infringement of intellectual property or making available any material that infringes any intellectual property rights or other proprietary rights of anyone else;</li>
                        <li>Make available any content or material that You do not have a right to make available under any law or contractual or fiduciary relationship, unless You own or control the rights thereto or have received all necessary consents for such use of the content;</li>
                        <li>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</li>
                        <li>Post, transmit or make available any material that contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or other computer programming routines, code, files or such other programs that may harm the Application/services, interests or rights of other users or limit the functionality of any computer software, hardware or telecommunications, or that may harvest or collect any data or personal information about other Users without their consent;</li>
                        <li>Access or use the Application/Website/Services/products in any manner that could damage, disable, overburden or impair any of the Application’s/Website’s servers or the networks connected to any of the servers on which the Application/Website is hosted;</li>
                        <li>Intentionally or unintentionally interfere with or disrupt the services or violate any applicable laws related to the access to or use of the Application/Website/Services/products, violate any requirements, procedures, policies or regulations of networks connected to the Application/Website/Services/products, or engage in any activity prohibited by these Terms;</li>
                        <li>Disrupt or interfere with the security of, or otherwise cause harm to, the Application/Website/Services/products, materials, systems resources, or gain unauthorized access to user accounts, passwords, servers or networks connected to or accessible through the Application/Website/Services/products or any affiliated or linked sites;</li>
                        <li>Interfere with, or inhibit any user from using and enjoying access to the Application/Website/ Services/products, or other affiliated sites, or engage in disruptive attacks such as denial of service attack on the Application/Website/Services/products;</li>
                        <li>Use deep-links, page-scrape, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to increase traffic to the Application/Website/Services/products, to access, acquire, copy or monitor any portion of the Application /Website/Services/products, or in any way reproduce or circumvent the navigational structure or presentation of the Application, or any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through the Application/ Website/Services/products;</li>
                        <li>Alter or modify any part of the Services;</li>
                        <li>Use the Services for purposes that are not permitted by: (i) these Terms; and (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdiction; or</li>
                        <li>Violate any of the terms specified under the Terms for the use of the Application /Website/Services/products.</li>
                        <li>In no event shall the Company, its officers, directors, employees, partners or agents be liable to You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits or any other claim arising out, of or in connection with, Your use of, or access to, the Application.</li>
                    </ul>
                    <label>Copyright and Trademark</label>
                    <p>1.	The Company, its suppliers and licensors expressly reserve all intellectual property rights in all text,programs, products, processes, technology, images, content and other materials relating to SG Learning and or Ghodawat Softech .Access to or use of the Site does not confer and should not be considered as conferring upon anyone any license to the Company or any third party’s intellectual property rights. All rights, including copyright, inand to the Site are owned by or licensed to the Company. Any use of the Site or its contents, including copying or storing it or them in whole or part is prohibited without the permission of the Company.</p>
                    <p>2.	You may not modify, distribute or re-post anything for any purpose. The names and logos and all related product and service names, design marks and slogans are the trademarks/service marks of the Company, its affiliates, its partners or its suppliers/service providers. All other marks are the property of their respective owners. No trademark or service mark license is granted in connection with the materials contained on the Application. References on the Site to any names, marks, products or services of third parties or hypertext links to third party sites or information are provided solely as a convenience to you and do nothing any way constitute or imply the Company’s endorsement, sponsorship or recommendation of the third party, the information, its product or services.</p>
                    <p>3.	The Company is not responsible for the content of any third party and does not make any representations regarding the content or accuracy of materials. </p>
                    <label>Disclaimer of Warranties and Liabilities</label>
                    <span>You expressly understand and agree that, to the maximum extent permitted by applicable law:</span>
                    <span>The Application , services and other materials provided by this Application is on an "as is" basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title,non-infringement, merchantability or fitness for a particular purpose. Without limiting the foregoing,SG Learning makes no warranty that</span>
                    <ul className="dot-list">
                        <li>Your requirements will be met or that services provided will be uninterrupted, timely, secure or error-free;</li>
                        <li>Materials, information and results obtained will be effective, accurate or reliable;</li>
                        <li>Any errors or defects in the Application , services or other materials will be corrected.</li>
                    </ul>
                    <p>To the maximum extent permitted by applicable law, we will have no liability related to user content arising under intellectual property rights, libel, privacy, publicity, obscenity or other laws. SG Learning  also disclaims all liability with respect to the misuse, loss, modification or unavailability of any user content.</p>
                    <p>The user understands and agrees that any material or data downloaded or otherwise obtained through the Application  done entirely at his/her own discretion and risk and he/she will be solely responsible for any damage to his/her computer systems or loss of data that results from the download of such material or data. We are not responsible for any typographical error leading to an invalid coupon. SG Learning  accepts no liability for any errors or omissions, with respect to any information provided to you whether on behalf of itself or third parties.</p>
                    <p>We shall not be liable for any third party product or services. The advertisement available on e-mail or Application  with respect to the third party website / Application or the products and services are for information purpose only.</p>
                    <label>General Provisions:</label>
                    <p><label>Notice: </label>All notices served by the Company shall be provided via email to Your account or as a general notification on the Application. Any notice to be provided to the Company should be sent to support@ghodawatsoftech .com.</p>
                    <p><label>Entire Agreement: </label>The Terms, along with the Privacy Policy, and any other guidelines made applicable to the Application from time to time, constitute the entire agreement between the Company and You with respect to Your access to or use of the Application, Website and the Services thereof.</p>
                    <p><label>Assignment:</label>You cannot assign or otherwise transfer Your obligations under the Terms, or any right granted hereunder to any third party. The Company’s rights under the Terms are freely transferable by the Company to any third parties without the requirement of seeking Your consent.</p>
                    <p><label>Severability:</label>If, for any reason, a court of competent jurisdiction finds any provision of the Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect.</p>
                    <p><label>Waiver:</label>Any failure by the Company to enforce or exercise any provision of the Terms, or any related right, shall not constitute a waiver by the Company of that provision or right.</p>
                    <p><label>Relationship:</label>You acknowledge that Your participation in the Application can make You an employee or agency or partnership or joint venture or franchise of the Company.</p>
                    <p>The Company provides these Terms so that You are aware of the terms that apply to your use of the Website/Application and Services. You acknowledge that the Company has given You a reasonable opportunity to review these Terms and that You have agreed to them.</p>
                    <label>Feedback:</label>
                    <p>Any feedback You provide with respect to the Application shall be deemed to be non-confidential. The Application shall be free to use such information on an unrestricted basis. Further, by submitting the feedback, You represent and warrant that (i) Your feedback does not contain confidential or proprietary information of You or of third parties; (ii) the Company is not under any obligation of confidentiality, express or implied, with respect to the feedback; (iii) the Application may have something similar to the feedback already under consideration or in development; and (iv) You are not entitled to any compensation or reimbursement of any kind from the Company for the feedback under any circumstances, unless specified.</p>
                    <p>Under no circumstances shall the Company be held responsible in any manner for any content provided by other users even if such content is offensive, hurtful or offensive. Please exercise caution while accessing the Application.</p>
                    <label>Customer Care:</label>
                    <p>We make all best endeavors to provide You with a pleasant experience. In the unlikely event that You face any issues, please contact us at support@ghodawatsoftech.com</p>
                </div>
                    :
                    <div className="terms-privacy-policy">
                        <ParticlesBg type="cobweb" bg={true} color="#18dcff" />
                        <h3>Privacy Policy</h3>
                        <label>General</label>
                        <p>We commit to respecting your online privacy data. We further recognize your need for appropriate protection and management of any personally identifiable information ("Personal Information") you share with us. Information that is considered personal about you by us includes, but is not limited to, your name, address, email address, phone number or other contact information. “sglearning.in” is a web console for educational organizations / Institutes  and “SG Learning App” is a mobile app (Android and iOS) will be considered as “SG Learning” throughout the document. The decision to grant access to the services of SG Learning is the prerogative of the site and its decision in this respect shall be final and binding on the proposed user. The user consents that SG Learning reserves its right to take all necessary measures to deny access to its services or the termination of services, if the terms and conditions of the present agreement are not complied with or are contravened or there is any violation of copyright, trademark or other valuable proprietary right as described in this agreement. Please read this privacy policy (“Policy“) carefully before using the Application, Website, its services and products, along with the Terms of Use (“ToU“) provided on the Application and on the Website. Your use of the Website, Application, or services in connection with the Application, Website or products (“Services“), or registrations with us through any mode or use of any products. </p>
                        <label>Registration</label>
                        <p>At the time of registration, the Company shall collect the following personally identifiable information about you: Name - including first and last name, email address, mobile phone number and other contact details, demographic profile (like your age, gender, address, etc.,). If you choose to link your social media account with your SG Learning  Account, we collect basic information about you from those social media platforms, such as: name, age, gender, location and e-mail address. Information collected about you is subject to the Privacy Policy of the Company, which may be read as part and parcel of these Terms of Use.</p>
                        <p>You are solely responsible for protecting the confidentiality of your username and password and any activity under the account will be deemed to have been done by you. In the event you provide us with false/inaccurate details or the Company has a reasonable ground to believe that false and inaccurate information has been furnished, we hold the right to permanently suspend your account.</p>
                        <span>We also collect and store personal information provided by you from time to time on the Site. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:</span>
                        <ul className="dot-list">
                            <li>To enable the provision of services opted for by you;</li>
                            <li>To communicate necessary account and product/service related information from time to time;</li>
                            <li>To allow you to receive quality customer care services;</li>
                            <li>To undertake necessary fraud and money laundering prevention checks, and comply with the highest security standards;</li>
                            <li>To comply with applicable laws, rules and regulations; and</li>
                            <li>To provide you with information and offers on products and services, on updates, on promotions, on related, affiliated or associated service providers and partners, that we believe would be of interest to you.</li>
                        </ul>
                        <label>Security</label>
                        <p>Transactions on the Website / Application are secure and protected. Any information entered by the User when transacting on the Website / Application is encrypted to protect the User against unintentional disclosure to third parties. The User’s credit and debit card information is not received, stored by or retained by the Company / Website / Application in any manner. This information is supplied by the User directly to the relevant payment gateway which is authorized to handle the information provided, and is compliant with the regulations and requirements of various banks and institutions and payment franchisees that it is associated with. SG Learning is concerned about safeguarding the confidentiality of your Information. SG Learning provides physical, electronic, and procedural safeguards to protect Information it processes and maintains. An illustration being, SG Learning limits access to this Information to authorized employees only who need to know that information in order to operate, develop or improve its Application/Services/products/Website. Please be aware that, although SG Learning Endeavour is to provide stringent security, for information it possesses and maintains, no security system can prevent all potential security breaches.</p>
                        <label>Alerts</label>
                        <p>SG Learning may alert you by email or phone (through sms/call) to inform you about new service offerings or other information which it perceives might be useful for you.</p>
                        <label>User communications</label>
                        <p>When you send an email or other communication to SG Learning , it may retain those communications in order to process your inquiries, respond to your requests and improve our Services.</p>
                        <label>Changes to this Statement</label>
                        <p>As the Company (Ghodawat Softech) evolves, its privacy policy will need to evolve as well to cover new situations. You are advised to review this Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
                        <label>User Consent</label>
                        <p>SG Learning  believes that every user of our Application/Services/Products/Website must be in a position to provide an informed consent prior to providing any Information required for the use of the Application/Services/Products/Website. By registering with it, you are expressly consenting to its collection, processing, storing, disclosing and handling of your information as set forth in this Policy now and as amended by us. Processing, your information in any way, including, but not limited to, collecting, storing, deleting, using, combining, sharing, transferring and disclosing information, all of which activities will take place in India. If you reside outside India your information will be transferred, processed and stored in accordance with the applicable data protection laws of India.</p>
                        <label>Contact Information</label>
                        <span>Ghodawat Softech(s) Grievance Officer shall undertake all reasonable efforts to address your grievances at the earliest possible opportunity. You may contact it at:</span>
                        <p><label>Address:</label>
                Plot. No. 437/8, At Po Chipari, Jaysingpur, Tal. Shirol, Dist Kolhapur-416101, MAharashtra , India.</p>
                        <span>Reach out to us on support@ghodawatsoftech.com, in case of any queries.</span>
                    </div>
            }
        </div>




    )
}

export default TermsPrivacyPolicy;